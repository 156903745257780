<template>
  <header class="w-full shadow bg-white fixed top-0 color z-30">
    <nav
      class="justify-between px-5 md:px-0 py-5 h-full items-center lg:container md:max-w-[85%] mx-auto flex"
    >
      <div class="h-full w-[100px] md:w-[150px]">
        <img
          src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388067/logo_w98rme.png"
          class=""
          alt="reddo logo"
        />
      </div>

      <div>
        <ul class="hidden md:flex items-center sm:gap-7 lg:gap-10 2xl:gap-20">
          <router-link to="/">
            <li
              class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
              :class="active.includes('home') ? 'text-secondary' : ''"
            >
              Home
            </li></router-link
          >
          <router-link to="/about"
            ><li
              class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
              :class="active.includes('about') ? 'text-secondary' : ''"
            >
              About Us
            </li></router-link
          >
          <li
            class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
          >
            <popper
              arrow
              :hover="true"
              :placement="'bottom'"
              :class="active.includes('services') ? 'text-secondary' : ''"
            >
              Services

              <template #content>
                <div class="w-[200px] md:w-[250px] my-2">
                  <router-link to="/conference">
                    <div
                      class="text-accent py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                      :class="
                        active.includes('/conference')
                          ? 'bg-secondary text-white'
                          : ''
                      "
                    >
                      meeting/training room
                    </div></router-link
                  >
                  <router-link to="/coworking">
                    <div
                      class="text-accent py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                      :class="
                        active.includes('/coworking')
                          ? 'bg-secondary text-white'
                          : ''
                      "
                    >
                      co-working space
                    </div></router-link
                  >

                  <router-link to="/private-coworking">
                    <div
                      class="text-accent py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                      :class="
                        active.includes('/private-coworking')
                          ? 'bg-secondary text-white'
                          : ''
                      "
                    >
                      private offices
                    </div></router-link
                  >
                  <router-link to="/executive-suite">
                    <div
                      class="text-accent font-bold py-3 px-5 cursor-pointer border-b border-primary-tint hover:bg-secondary-shades hover:text-white"
                      :class="
                        active.includes('/executive-suite')
                          ? 'bg-secondary text-white'
                          : ''
                      "
                    >
                      Executive Suite
                    </div></router-link
                  >
                  <router-link to="/virtual">
                    <div
                      class="text-accent font-bold py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white"
                      :class="
                        active.includes('/virtual')
                          ? 'bg-secondary text-white'
                          : ''
                      "
                    >
                      virtual Offices
                    </div></router-link
                  >
                </div>
              </template>
            </popper>
          </li>
          <router-link to="/gallery"
            ><li
              class="text-xs font-bold md:text-sm cursor-pointer lg:text-base hover:text-secondary uppercase"
              :class="active.includes('gallery') ? 'text-secondary' : ''"
            >
              gallery
            </li></router-link
          >
          <router-link to="/contact">
            <li
              class="font-bold text-xs md:text-sm cursor-pointer lg:text-base hover:text-secondary uppercase"
              :class="active.includes('contact') ? 'text-secondary' : ''"
            >
              Contact
            </li></router-link
          >
        </ul>
      </div>

      <router-link to="/contact">
        <li
          class="font-bold bg-secondary hidden md:block text-white py-2 lg:py-4 px-6 lg:px-10 uppercase rounded-full text-xs md:text-sm lg:text-base hover:bg-secondary hover:"
        >
          Get a quote
        </li>
      </router-link>

      <div @click="$emit('openDrawerNav')" class="md:hidden">
        <img
          src="/icons/menu.svg"
          class="h-[30px] w-[30px] md:h-[50px] md:w-[50px]"
          alt="menu"
        />
      </div>
    </nav>
  </header>
</template>

<script>
import Popper from "vue3-popper";
export default {
  components: {
    Popper,
  },

  props: {
    color: {
      type: String,
    },
  },

  data() {
    return {};
  },

  computed: {
    active() {
      return this.$route.path;
    },
  },
};
</script>
