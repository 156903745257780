import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./styles/main.css";
import Toast from "vue-toastification";
import VueEasyLightbox from "vue-easy-lightbox";
//in your `main.js` file

import "animate.css";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

createApp(App).use(Toast).use(VueEasyLightbox).use(router).mount("#app");
