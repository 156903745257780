import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/faqs",
    name: "faqs",
    // route level code-splitting
    // this generates a separate chunk (faqs.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "faqs" */ "../views/FaqView.vue"),
  },

  {
    path: "/conference",
    name: "conference",
    // route level code-splitting
    // this generates a separate chunk (conference.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dominica" */ "../views/services/ConferenceRoomView.vue"
      ),
  },
  {
    path: "/coworking",
    name: "coworking",
    // route level code-splitting
    // this generates a separate chunk (coworking.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "dominica" */ "../views/services/BusinessSuiteView.vue"
      ),
  },
  {
    path: "/private-coworking",
    name: "privateCoworking",
    // route level code-splitting
    // this generates a separate chunk (privateCoworking.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "bahamas" */ "../views/services/PrivateCoworkingView.vue"
      ),
  },
  {
    path: "/virtual",
    name: "virtual",
    // route level code-splitting
    // this generates a separate chunk (virtual.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "granada" */ "../views/services/VirtualOfficeView.vue"
      ),
  },
  {
    path: "/executive-suite",
    name: "executiveSuite",
    // route level code-splitting
    // this generates a separate chunk (executiveSuite.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "saint" */ "../views/services/ExecutiveSuiteView.vue"
      ),
  },
  {
    path: "/gallery",
    name: "gallery",
    // route level code-splitting
    // this generates a separate chunk (gallery.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "gallery" */ "../views/GalleryView.vue"),
  },

  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactView.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    // route level code-splitting
    // this generates a separate chunk (not-found.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "not-found" */ "../components/NotFound.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
