<template>
  <section>
    <SwiperHero />

    <welcome-page></welcome-page>

    <SwiperSection />

    <AmenitiesSection />

    <TestimonialPage />

    <BlogSection />
  </section>
</template>

<script>
import SwiperHero from "../components/homepage/SwiperHero";
import SwiperSection from "../components/homepage/SwiperSection";
import WelcomePage from "../components/homepage/WelcomePage";
import AmenitiesSection from "../components/AmenitiesSection";
import TestimonialPage from "../components/homepage/TestimonialPage";
import BlogSection from "../components/homepage/BlogSection";
export default {
  name: "HomeView",
  components: {
    SwiperHero,
    WelcomePage,
    SwiperSection,
    AmenitiesSection,
    TestimonialPage,
    BlogSection,
  },
};
</script>
