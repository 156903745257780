<template>
  <section class="pt-20 md:pt-32 md:pb-20 bg-[#f4f4f5]">
    <div class="px-5 lg:l-width pb-10 md:pb-20">
      <div class="text-center pb-10 lg:pb-20">
        <h3 class="text-primary-shades font-medium tracking-wide">
          WHAT OUR MEMBERS SAY
        </h3>
        <h2
          class="text-3xl md:text-4xl lg:text-6xl py-5 md:py-8 font-bold text-accent"
        >
          Become part of our community.
        </h2>
        <p
          class="mx-auto md:max-w-[60%] tracking-wide text-base md:text-lg lg:text-xl"
        >
          Experience what our members have to say about their time at our
          coworking space.
        </p>
      </div>

      <swiper
        :slidesPerView="1"
        :spaceBetween="50"
        :loop="true"
        :autoplay="{
          delay: 8000,
          disableOnInteraction: false,
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="mySwiper h-full pb-10 md:pb-0 md:h-[400px]"
      >
        <swiper-slide>
          <div
            class="rounded-3xl p-2 max-h-[400px] w-full md:w-[600px] mx-auto bg-gradient-to-r from-grey-shades to-primary-shades"
          >
            <div
              class="rounded-lg w-full p-10 text-start shadow-2xl bg-white dark:bg-gray-900"
            >
              <p class="text-gray-700 dark:text-gray-300">
                Great place to do your work, wonderful ambience, located in a
                prime location and good place for networking. I highly recommend
              </p>

              <div class="mt-8 flex gap-4 items-center">
                <img
                  class="h-12 w-12 rounded-full"
                  src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388112/segun_jvng1k.png"
                  alt="testimonial1"
                />
                <div>
                  <h3 class="text-lg font-medium text-gray-700">
                    Oluwasegun Oni
                  </h3>
                  <rating-star></rating-star>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="rounded-3xl p-2 max-h-[400px] w-full md:w-[600px] mx-auto bg-gradient-to-r from-grey-shades to-primary-shades"
          >
            <div class="rounded-lg p-10 text-start bg-white dark:bg-gray-900">
              <p class="text-gray-700 dark:text-gray-300">
                Awesome place! Conducive and excellent ambience, state of the
                art office furnitures . Stable power with a Nice space for
                relaxation. Best work place.
              </p>

              <div class="mt-8 flex gap-4 items-center">
                <img
                  class="h-12 w-12 rounded-full"
                  src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388116/tobi_i3cjof.png"
                  alt="testimonial2"
                />
                <div>
                  <h3 class="text-lg font-medium text-gray-700">
                    Tobi Oluwamotire
                  </h3>
                  <rating-star></rating-star>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="rounded-3xl p-2 max-h-[400px] w-full md:w-[600px] mx-auto bg-gradient-to-r from-grey-shades to-primary-shades"
          >
            <div class="rounded-lg p-10 text-start bg-white dark:bg-gray-900">
              <p class="text-gray-700 dark:text-gray-300">
                Exquisite office space, 24 Internet, serene environment,
                perfectly located on the island. Affordable pricing. You don't
                want to be anywhere else.
              </p>

              <div class="mt-8 flex gap-4 items-center pb-10">
                <img
                  class="h-12 w-12 rounded-full"
                  src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388052/eno_xdwuci.png"
                  alt="testimonial3"
                />
                <div>
                  <h3 class="text-lg font-medium text-gray-700">
                    Enobong _the_profitable_investor
                  </h3>
                  <rating-star></rating-star>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div
            class="rounded-3xl p-2 max-h-[400px] w-full md:w-[600px] mx-auto bg-gradient-to-r from-grey-shades to-primary-shades"
          >
            <div class="rounded-lg p-10 text-start bg-white dark:bg-gray-900">
              <p class="text-gray-700 dark:text-gray-300">
                I love the place especially for it’s serenity, I was able to
                concentrate on my work and their power is soo stable. Kudos👏 …
              </p>

              <div class="mt-8 flex gap-4 items-center pb-5">
                <img
                  class="h-12 w-12 rounded-full"
                  src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388112/shoke_opuzoq.png"
                  alt="testimonial4"
                />
                <div>
                  <h3 class="text-lg font-medium text-gray-700">
                    Shorunke Tolulope
                  </h3>
                  <rating-star></rating-star>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/grid";
import "swiper/css/pagination";

// import required modules
import { Grid, Pagination, Autoplay } from "swiper/modules";
import RatingStar from "../RatingStar.vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
    RatingStar,
  },
  setup() {
    return {
      modules: [Grid, Pagination, Autoplay],
    };
  },
};
</script>
