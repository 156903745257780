<template>
  <section class="py-10 md:py-20 bg-[#fcfcfc]">
    <div class="px-5 lg:l-width">
      <div class="pb-10 md:pb-20">
        <h3
          class="text-primary-shades font-medium text-lg tracking-wide animate__animated animate__zoomIn"
        >
          OUR AMENITIES
        </h3>

        <div
          class="grid grid-cols-1 items-center md:grid-cols-2 gap-3 md:gap-10 w-full"
        >
          <div
            class="text-3xl max-w-full md:text-4xl lg:text-6xl flex-1 py-5 md:py-8 font-bold text-accent animate__animated animate__zoomIn"
          >
            All your essentials needs for comfort and productivity
          </div>
          <div
            class="tracking-wide max-w-full text-base md:text-lg lg:text-xl animate__animated animate__zoomIn"
          >
            Elevate your workday with amenities that enhance productivity and
            comfort and maximize your workday with amenities designed to boost
            productivity and comfort. Here's what you can expect:
          </div>
        </div>
      </div>

      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-10 animate__animated animate__fadeInUp animate__delay-1s"
      >
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/internet.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Fast internet connection</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img
            src="/icons/electricity.svg"
            class="h-[30px] w-[30px]"
            alt="..."
          />
          <h3>Steady power supply</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/support.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>front desk service</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/meeting.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Meeting Rooms</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/kitchen.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Kitchen & Lounge</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/parking.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Secured parking spaces</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/dispatch.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Dispatch riders</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/virtual.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Virtual Office Services</h3>
        </div>

        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/security.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>Security</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/plant.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>indoor live plants.</h3>
        </div>
        <div
          class="p-5 rounded flex gap-5 bg-white capitalize font-bold text-xl text-accent shadow-lg"
        >
          <img src="/icons/artwork.svg" class="h-[30px] w-[30px]" alt="..." />
          <h3>artwork for offices</h3>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
