<template>
  <header
    class="h-full shadow bg-white fixed top-0 right-0 w-[250px] color z-40 animate__animated animate__fadeInRight"
  >
    <div @click="closeNav" class="flex justify-end m-3">
      <div class="p-2 rounded-full mt-3 bg-primary-shades cursor-pointer">
        <img
          src="/icons/close.svg"
          class="h-[20px] w-[20px] md:h-[50px] md:w-[50px]"
          alt="close"
        />
      </div>
    </div>

    <nav class="justify-center h-full mt-40 flex">
      <ul class="flex flex-col items-center gap-10">
        <router-link to="/">
          <li
            @click="closeNav()"
            class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
            :class="active.includes('home') ? 'text-secondary' : ''"
          >
            Home
          </li></router-link
        >
        <router-link to="/about"
          ><li
            @click="closeNav()"
            class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
            :class="active.includes('about') ? 'text-secondary' : ''"
          >
            About Us
          </li></router-link
        >
        <li
          class="font-bold text-xs md:text-sm lg:text-base cursor-pointer hover:text-secondary uppercase"
        >
          <popper
            arrow
            :hover="true"
            :placement="'bottom'"
            class=""
            :class="active.includes('services') ? 'text-secondary' : ''"
          >
            Services

            <template #content>
              <div class="w-[200px] md:w-[250px] my-2 bg-white shadow-md">
                <router-link to="/conference">
                  <div
                    @click="closeNav()"
                    class="text-black py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                    :class="
                      active.includes('/conference')
                        ? 'bg-secondary text-white'
                        : ''
                    "
                  >
                    meeting/training room
                  </div></router-link
                >
                <router-link to="/coworking">
                  <div
                    @click="closeNav()"
                    class="text-black py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                    :class="
                      active.includes('/coworking')
                        ? 'bg-secondary text-white'
                        : ''
                    "
                  >
                    co-working space
                  </div></router-link
                >

                <router-link to="/private-coworking">
                  <div
                    @click="closeNav()"
                    class="text-black py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white border-b border-primary-tint"
                    :class="
                      active.includes('/private-coworking')
                        ? 'bg-secondary text-white'
                        : ''
                    "
                  >
                    private offices
                  </div></router-link
                >

                <router-link to="/executive-suite">
                  <div
                    @click="closeNav()"
                    class="text-black font-bold py-3 px-5 cursor-pointer border-b border-primary-tint hover:bg-secondary-shades hover:text-white"
                    :class="
                      active.includes('/executive-suite')
                        ? 'bg-secondary text-white'
                        : ''
                    "
                  >
                    Executive Suite
                  </div></router-link
                >

                <router-link to="/virtual">
                  <div
                    @click="closeNav()"
                    class="text-black font-bold py-3 px-5 cursor-pointer hover:bg-secondary-shades hover:text-white"
                    :class="
                      active.includes('/executive-office')
                        ? 'bg-secondary text-white'
                        : ''
                    "
                  >
                    virtual Offices
                  </div></router-link
                >
              </div>
            </template>
          </popper>
        </li>
        <router-link to="/gallery"
          ><li
            @click="closeNav()"
            class="text-xs font-bold md:text-sm cursor-pointer lg:text-base hover:text-secondary uppercase"
            :class="active.includes('gallery') ? 'text-secondary' : ''"
          >
            gallery
          </li></router-link
        >
        <router-link to="/contact">
          <li
            @click="closeNav()"
            class="font-bold text-xs md:text-sm cursor-pointer lg:text-base hover:text-secondary uppercase"
            :class="active.includes('contact') ? 'text-secondary' : ''"
          >
            Contact
          </li></router-link
        >
      </ul>

      <router-link to="/contact">
        <li
          @click="closeNav"
          class="font-bold bg-secondary hidden md:block text-white py-2 lg:py-4 px-6 lg:px-10 uppercase rounded-full text-xs md:text-sm lg:text-base hover:bg-secondary hover:"
        >
          Get a quote
        </li>
      </router-link>
    </nav>
  </header>
</template>

<script>
import Popper from "vue3-popper";
export default {
  name: "NavDrawer",
  components: {
    Popper,
  },

  props: {
    color: {
      type: String,
    },
  },

  data() {
    return {};
  },

  methods: {
    closeNav() {
      // Emitting a custom event with the name 'button-clicked'
      this.$emit("close-button");
    },
  },

  computed: {
    active() {
      return this.$route.path;
    },
  },
};
</script>
