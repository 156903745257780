<template>
  <!-- Footer Section -->
  <section class="bg-primary py-[40px] md:py-[80px] px-5">
    <div class="l-width">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 justify-between gap-10"
      >
        <div class="space-y-5 md:space-y-8">
          <div class="w-[150px]">
            <img
              src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720388067/logo_w98rme.png"
              class=""
              alt="reddo logo"
            />
          </div>
          <p class="text-sm md:text-base lg:text-lg text-white">
            Reddo Workspace is a service-based organization that provides
            exclusive collaborative workspaces and private offices for
            individuals, business startups, and large corporations
          </p>
        </div>
        <div class="space-y-5 md:space-y-8 md:justify-self-center">
          <h3
            class="font-bold text-white capitalize text-base md:text-lg lg:text-2xl"
          >
            Explore
          </h3>
          <ul
            class="text-xs md:text-sm lg:text-base space-y-5 capitalize text-white"
          >
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/" class="hover:text-secondary"
                >home
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/about" class="hover:text-secondary"
                >about
              </router-link>
            </li>

            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/gallery" class="hover:text-secondary"
                >gallery
              </router-link>
            </li>
            <!-- <li class="hover:text-secondary cursor-pointer">
              <router-link to="/blog" class="hover:text-secondary"
                >blog
              </router-link>
            </li> -->
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/contact" class="hover:text-secondary"
                >contact
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/faqs" class="hover:text-secondary"
                >FAQs
              </router-link>
            </li>
          </ul>
        </div>
        <div class="space-y-5 md:space-y-8 md:justify-self-center">
          <h3
            class="font-bold text-white capitalize text-base md:text-lg lg:text-2xl"
          >
            services
          </h3>
          <ul
            class="text-xs md:text-sm lg:text-base space-y-5 capitalize text-white"
          >
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/conference" class="hover:text-secondary"
                >Meeting/Training room
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/coworking" class="hover:text-secondary"
                >co-working spaces
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/private-coworking" class="hover:text-secondary"
                >private offices
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/executive-suite" class="hover:text-secondary"
                >executive suites
              </router-link>
            </li>
            <li class="hover:text-secondary cursor-pointer">
              <router-link to="/virtual" class="hover:text-secondary"
                >virtual Offices
              </router-link>
            </li>
          </ul>
        </div>

        <div class="space-y-5 md:space-y-8">
          <h3
            class="font-bold text-white capitalize text-base md:text-lg lg:text-2xl"
          >
            Contact
          </h3>
          <div class="text-xs md:text-sm lg:text-base space-y-5 md:space-y-8">
            <div class="flex items-center gap-5 text-white">
              <img src="/icons/location.svg" alt="location" />
              <p>
                2nd Floor, Wing 5, 4C Idowu Martins Street, Victoria Island.
                Lagos.
              </p>
            </div>
            <div class="flex items-center gap-5 text-white">
              <img src="/icons/call.svg" alt="call" />
              <div>
                <p class="hover:bg-primary-shades">
                  <a href="tel:+2349087700619"> +234 908 770 0619</a>
                </p>
                <p class="hover:bg-primary-shades">
                  <a href="tel:+2349087700608"> +234 908 770 0608</a>
                </p>
              </div>
            </div>
            <div class="flex items-center gap-5 text-white">
              <img src="/icons/email.svg" alt="email" />
              <div>
                <p class="hover:bg-primary-shades">
                  <a
                    href="mailto:info@reddo.ng?subject=Inquiry%20about%20Reddo%20Spaces&body=Hello!%20I'm%20emailing%20from%20the%20website,%20I%20want%20to%20enquire%20about%20Reddo%20Spaces."
                    >info@reddo.ng
                  </a>
                </p>
                <p class="hover:bg-primary-shades">
                  <a
                    href="mailto:hello@reddo.ng?subject=Inquiry%20about%20Reddo%20Spaces&body=Hello!%20I'm%20emailing%20from%20the%20website,%20I%20want%20to%20enquire%20about%20Reddo%20Spaces."
                    >hello@reddo.ng
                  </a>
                </p>
              </div>
            </div>

            <div class="flex items-center gap-2 mt-10 md:mt-20">
              <div class="cursor-pointer max-h-[42px] max-w-[42px]">
                <a
                  href="https://x.com/reddo61127070"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="/icons/twitter.png"
                    class="object-cover hover:scale-125 transition ease-in-out"
                    alt="twitter"
                /></a>
              </div>

              <div class="cursor-pointer max-h-[35px] max-w-[35px]">
                <a
                  href="https://www.facebook.com/reddoworkspaces"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="/icons/facebook.svg"
                    class="object-cover hover:scale-125 transition ease-in-out"
                    alt="facebook"
                /></a>
              </div>

              <div class="cursor-pointer max-h-[45px] max-w-[45px]">
                <a
                  href="https://www.youtube.com/@REDDO_WORKSPACE"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="/icons/youtube.svg"
                    class="object-cover hover:scale-125 transition ease-in-out"
                    alt="youtube"
                /></a>
              </div>
              <div class="cursor-pointer max-h-[30px] max-w-[30px]">
                <a
                  href="https://www.linkedin.com/company/reddo-workspaces/"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="/icons/linkedin.svg"
                    class="object-cover hover:scale-125 transition ease-in-out"
                    alt="linkedin"
                /></a>
              </div>
              <div class="cursor-pointer max-h-[45px] max-w-[45px]">
                <a
                  href="https://instagram.com/reddo_workspaces"
                  target="_blank"
                  rel="noopener noreferrer"
                  ><img
                    src="/icons/instagram.png"
                    class="object-cover hover:scale-125 transition ease-in-out"
                    alt="instagram"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr
      class="my-6 space-y-8 lg:space-y-10 border-0.5 bg-[#ddd] l-width sm:mx-auto lg:my-14"
    />

    <p class="text-center text-sm md:text-base text-accent-shades">
      &copy; Copyright {{ new Date().getFullYear() }} by Reddo Workspace. All
      right Reserved.
    </p>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "FooterComponent",
};
</script>
