<template>
  <div>
    <!-- header -->
    <div class="relative overflow-hidden">
      <div
        class="h-[600px] md:h-screen w-screen"
        style="
          background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720387626/1_ujj6uo.jpg');
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <div class="bg-[#000] bg-opacity-70 h-full">
          <div class="flex items-center h-full">
            <div class="pl-5 md:r-width space-y-5 2xl:space-y-10">
              <h1
                class="animate__animated capitalize animate__fadeInUp text-[32px] sm:text-[40px] md:text-[60px] 2xl:text-[80px] text-white font-extrabold leading-snug"
              >
                Work has never been <br class="hidden md:inline" />
                this Enjoyable
              </h1>
              <p
                class="animate__animated animate__fadeInUp animate__delay-1s text-white text-xs md:text-base lg:text-xl 2xl:text-2xl"
              >
                Offices designed to power productivity and
                <br class="hidden lg:inline" />
                inspire creativity
              </p>

              <div
                class="animate__animated animate__fadeInUp animate__delay-2s pt-5 md:pt-10"
              >
                <router-link to="/contact">
                  <button
                    class="uppercase bg-primary-shades hover:bg-primary text-xs sm:text-sm 2xl:text-lg font-bold cursor-pointer text-white px-3 py-2.5 md:px-6 2xl:px-10 md:py-3 2xl:py-5 rounded-md"
                  >
                    get a quote
                  </button></router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  setup() {
    return {};
  },
};
</script>
