<template>
  <section class="py-20 md:l-width px-5">
    <div class="text-center pb-10 lg:pb-20">
      <h3
        class="text-primary-shades text-lg lg:text-xl font-medium uppercase tracking-wide"
      >
        adaptive workspaces
      </h3>
      <h2
        class="text-3xl md:text-4xl lg:text-6xl py-5 md:py-8 font-bold text-accent"
      >
        Your ideal workspace awaits
      </h2>
      <p
        class="mx-auto md:max-w-[70%] tracking-wide text-base md:text-lg lg:text-xl"
      >
        Discover a variety of customizable workspace solutions, including
        private offices, open workstations, and virtual offices. Tailor your
        workspace to fit your specific preferences and needs.
      </p>
    </div>

    <div class="l-width h-full">
      <swiper
        :slidesPerView="1"
        :spaceBetween="50"
        :freeMode="true"
        :pagination="{
          clickable: true,
        }"
        :loop="true"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: false,
        }"
        :breakpoints="{
          '640': {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          '768': {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          '1024': {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }"
        :modules="modules"
        class="mySwiper h-[500px] pb-20 flex justify-cover"
      >
        <swiper-slide class="">
          <div class="relative cursor-pointer hover:opacity-70">
            <router-link to="/conference"
              ><div
                style="
                  background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720643533/conference_iif3qe.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                "
                class="h-[400px] rounded-2xl"
              ></div>
              <div
                class="absolute bottom-0 z-20 rounded-2xl w-full bg-accent pl-5"
              >
                <h3
                  class="text-white text-lg md:text-xl uppercase lg:text-2xl font-bold py-3"
                >
                  meeting / training room
                </h3>
              </div>
            </router-link>
          </div>
        </swiper-slide>
        <swiper-slide class="">
          <div class="relative cursor-pointer hover:opacity-70">
            <router-link to="/coworking"
              ><div
                style="
                  background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720387627/4_qtizza.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                "
                class="h-[400px] rounded-2xl"
              ></div>
              <div
                class="absolute bottom-0 z-20 rounded-2xl w-full bg-accent pl-5"
              >
                <h3
                  class="text-white text-lg md:text- uppercase lg:text-2xl font-bold py-3"
                >
                  co-working spaces
                </h3>
              </div></router-link
            >
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="relative cursor-pointer hover:opacity-70">
            <router-link to="/private-coworking"
              ><div
                style="
                  background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720388047/dave_idypdy.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                "
                class="h-[400px] rounded-2xl"
              ></div>
              <div
                class="absolute bottom-0 z-20 rounded-2xl w-full bg-accent pl-5"
              >
                <h3
                  class="text-white uppercase text-lg md:text-xl lg:text-2xl font-bold py-3"
                >
                  private offices
                </h3>
              </div>
            </router-link>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="relative cursor-pointer hover:opacity-70">
            <router-link to="/executive-suite"
              ><div
                style="
                  background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720643999/cuba_yfchyv.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                "
                class="h-[400px] rounded-2xl"
              ></div>
              <div
                class="absolute bottom-0 z-20 rounded-2xl w-full bg-accent pl-5"
              >
                <h3
                  class="text-white uppercase text-lg md:text-xl lg:text-2xl font-bold py-3"
                >
                  executive suite
                </h3>
              </div></router-link
            >
          </div>
        </swiper-slide>

        <swiper-slide>
          <router-link to="/virtual"
            ><div class="relative cursor-pointer hover:opacity-70">
              <div
                style="
                  background-image: url('https://res.cloudinary.com/daeqws8jv/image/upload/v1720388122/virtual_h0e43d.png');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: cover;
                "
                class="h-[400px] rounded-2xl"
              ></div>
              <div
                class="absolute bottom-0 z-20 rounded-2xl w-full bg-accent pl-5"
              >
                <h3
                  class="text-white uppercase text-lg md:text-xl lg:text-2xl font-bold py-3"
                >
                  Virtual offices
                </h3>
              </div>
            </div></router-link
          >
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination, Autoplay } from "swiper/modules";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [FreeMode, Pagination, Autoplay],
    };
  },
};
</script>
