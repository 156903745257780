<template>
  <section class="px-5 md:l-width mx-auto py-24 md:py-32 2xl:py-40 relative">
    <div class="flex flex-col lg:flex-row gap-10 md:gap-20 justify-center">
      <div class="md:w-1/2 animate__animated animate__zoomIn animate__delay-1s">
        <img
          src="https://res.cloudinary.com/daeqws8jv/image/upload/v1720387673/24_on8ciz.jpg"
          class="w-[100%] h-[70vh]"
          alt="..."
        />
      </div>
      <div
        class="md:w-1/2 mt-5 lg:mt-0 animate__animated animate__zoomIn animate__delay-1s"
      >
        <p
          class="text-primary-shades font-medium text-lg lg:text-xl tracking-wide"
        >
          WELCOME TO OUR COZY WORKSPACE
        </p>
        <h2
          class="text-3xl lg:text-4xl 2xl:text-6xl py-8 2xl:py-10 font-bold text-accent"
        >
          A Space Hub for Creativity and Productivity
        </h2>

        <p class="tracking-wide text-base md:text-lg">
          Coworking spaces are vibrant environments that inspire collaboration
          and creativity, providing flexibility and amenities conducive to work.
          They offer diverse benefits, making them popular among individuals and
          businesses.
        </p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10 2xl:mt-20">
          <div class="animate__animated animate__zoomIn animate__delay-2s">
            <div>
              <img src="/icons/balance.svg" alt="icon1" />
            </div>
            <h3
              class="font-bold text-accent py-3 text-lg md:text-xl lg:text-2xl"
            >
              Balance work and life
            </h3>
            <p class="text-base md:text-lg">
              Creating a separation between work and home can enhance your
              work-life balance. Coworking spaces provide a dedicated
              environment for focused productivity.
            </p>
          </div>
          <div class="animate__animated animate__zoomIn animate__delay-3s">
            <div>
              <img src="/icons/effect.svg" alt="icon2" />
            </div>
            <h3
              class="font-bold text-accent py-3 text-lg md:text-xl lg:text-2xl"
            >
              Cost effectiveness
            </h3>
            <p class="text-base md:text-lg">
              Coworking spaces offer cost-effective solutions by allowing you to
              pay only for the space and services you need, minimizing overhead
              costs.
            </p>
          </div>
          <div class="animate__animated animate__zoomIn animate__delay-4s">
            <div>
              <img src="/icons/access.svg" alt="icon3" />
            </div>
            <h3
              class="font-bold text-accent py-3 text-lg md:text-xl lg:text-2xl"
            >
              Access to facilities
            </h3>
            <p class="text-base md:text-lg">
              Coworking spaces offer affordable options that can be customized
              to fit your specific needs, thereby reducing overhead costs.
            </p>
          </div>
          <div class="animate__animated animate__zoomIn animate__delay-5s">
            <div>
              <img src="/icons/productivity.svg" alt="icon4" />
            </div>
            <h3
              class="font-bold text-accent py-3 text-lg md:text-xl lg:text-2xl"
            >
              Enhanced productivity.
            </h3>
            <p class="text-base md:text-lg">
              Surrounded by like-minded individuals who share your drive and
              ambition, you'll foster an environment that fuels motivation,
              sharpens focus, and leads to increased productivity.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="absolute md:bottom-[-150px] bottom-[-80px] right-14 md:right-20"
    >
      <div
        @click="openModal"
        class="md:w-[250px] h-[150px] w-[150px] md:h-[250px] text-white text-lg md:text-2xl font-extrabold cursor-pointer border-[12px] md:border-[24px] border-primary-shades hover:border-primary hover:bg-primary-shades rounded-full bg-primary flex justify-center items-center"
      >
        <p class="p-5 text-center capitalize">Take a virtual tour</p>
      </div>
    </div>
  </section>

  <section>
    <div
      class="mx-auto h-[200px] md:h-[600px] w-full overflow-y-scroll bg-contain bg-fixed bg-center bg-no-repeat shadow-lg"
      style="
        background-image: url('/images/45.jpg');
        max-height: 60vh;
        max-width: 100%;
        object-fit: cover;
        object-position: center;
      "
    ></div>
  </section>

  <div
    v-if="show"
    className="z-50 h-full fixed  w-full bg-[#333] bg-opacity-70 overflow-y-auto inset-0 outline-none focus:outline-none"
  >
    <div
      class="max-w-[90%] max-h-[90%] px-3 mx-auto animate__animated animate__zoomIn"
    >
      <div class="flex justify-end">
        <p
          @click="closeModal"
          class="p-3 bg-grey-tint hover:bg-grey-shades rounded-full cursor-pointer"
        >
          <img src="/icons/close.svg" alt="close" />
        </p>
      </div>
      <video width="100%" height="100%" autoplay>
        <source
          src="https://res.cloudinary.com/daeqws8jv/video/upload/v1720711531/herovid_wqsuqp.mp4"
        />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      loading: false,
    };
  },

  methods: {
    openModal() {
      this.loading = true;
      this.show = true;
      this.loading = false;
    },

    closeModal() {
      this.show = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
